import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  Row,
  Tab,
  Tabs,
  productData
} from "react-bootstrap";
import rotate from "../assets/images/rotate.png";
import CanvasTest from "./CanvasTest";
import arrow from "../assets/images/arrow.png";
import upload from "../assets/images/upload.png";
import touchscreen from "../assets/images/touchscreen.png";
import cart from "../assets/images/cart.png";
import { fabric } from "fabric";
import axios from "axios";
import FabricOptions from './FabricOptions';
// import Canvas from "./Canvas"

export default function SideBar(props) {
  // var count  = 1;

  const {
    canvas,
    basic1,
    setCh,
    inputValue,
    setinputValue,
    setOverLay,
    overLay,
    setoverLayImg,
    overlayImg,
    setdesignSize,
    designSize1,
    ch,
    cw,
    setCw,
    cmHeight,
    cmWidth,
    productData ,
    fabricData ,
    setfabricData ,
    fabricSize ,
    setfabricSize ,
    fabricName ,
    setfabricName ,
    amount ,
    setamount ,
    url
  } = props;

   
  // ******  state
  //  const [inputValue, setinputValue] = useState()
  // const [designSize1, setdesignSize] = useState(5);
  const [rotation, setrotation] = useState(false);
  const [show, setShow] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActiveNew, setIsActiveNew] = useState(false);
  const [rotatedImgURL, setrotatedImgURL] = useState();
  //  const [overLay, setOverLay] = useState(0);
  //  const [overlayImg, setoverLayImg] = useState(null);
  const [pattern, setpattern] = useState(null);
  const [qty , setqty] = useState(1);
  const [size , setSize]=useState(null);
  const [term , setTerm]=useState(null);
  const [sdisplay , ssetdisplay]=useState('none');
  const [tdisplay , tsetdisplay]=useState('none');
  var imgUrl = inputValue;
 

  
 






  useEffect(() => {
   
    

    if (overLay == 1) {
      if (overlayImg != null) {
        const ctx = canvas?.getContext("2d");
        ctx.clearRect(0, 0, canvas?.width, canvas?.height);
        var dummy = new Image();
        dummy.src = "dummy";

      ctx.drawImage(dummy, 0, 0, canvas?.width, canvas?.height);      


        if (pattern == "basic") {
          basic2();
          console.log("overly basic");
        } else if (pattern == "halfBrick") {
          halfBrick1();
        } else if (pattern == "halfDrop") {
          halfDrop1();
        }
      }
    }
  }, [overlayImg]);

  //    *********** input value
 
  

  function handleChange(e) {
    axios.post(url,{  "source": "overlay_image","overlay":e.target.files[0] },
    {headers: { "Content-Type": "multipart/form-data"}}
    )
    .then(res => {
      if(res?.data){
        console.log(res,"res");
        setinputValue(res?.data?.image_url)
      }
    })
    // console.log(e.target.files);
   // setinputValue(URL.createObjectURL(e.target.files[0]));
    // uploadImage()
    // setTimeout(uploadImage,5000)
    basic2();
  }
  

  // ******** handleClick
  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  // **********  handleClick1
  const handleClick1 = () => {
    // 👇️ toggle
    setIsActive((current) => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };

  // ***********  handleClick2
  const handleClick2 = () => {
    // 👇️ toggle
    setIsActiveNew((current) => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };

  // ***************** Remove Overlay
  const removeOverlay = () => {
    setoverLayImg("dummy");
  };
  console.log(cw, ch, "ch cw");

  // *******************   handleOverlay

  const handleOverlay = () => {
    const ctx = canvas.getContext("2d");
    // var dummy = new Image();
    // dummy.src = "dummy";

    // ctx.drawImage(dummy, 0, 0, canvas?.width, canvas?.height);
    var overlay = new Image();
    overlay.src = overlayImg;

    overlay.onload = function () {
      ctx.drawImage(overlay, 0, 0, canvas?.width, canvas?.height);
    };

    setOverLay(1);
  };

  // *************  uploadImage *********
  // useEffect(() => {

  function uploadImage() {
    console.log("uploadImage Called");
    var ctx = canvas.getContext("2d");
    var image = new Image();
    image.onload = function (img) {
      if (designSize1) {
        var img = new fabric.Image(image, {
          angle: 0,
          width: 300,
          height: 300,
          left: 25,
          top: 25,
        });
        console.log(img, "img from upload Img sidebar");
      } else {
        var img = new fabric.Image(image, {
          angle: 0,
          width: 300,
          height: 300,
          left: 50,
          top: 20,
        });
      }
      canvas?.add(img);
    };

    image.src = imgUrl;
  }
  // }, [third])
  // *********** Design Size

  const handleDesignSize = (arg) => {
    console.log('argument value' , arg);
    setdesignSize(arg)
    // switch (arg) {
    //   case 5:
    //     return setdesignSize(arg);
    //   case 10:
    //     return setdesignSize(arg);
    //   case 15:
    //     return setdesignSize(arg);
    //   case 20:
    //     return setdesignSize(arg);
    //   default:
    //     return  
    // }
  };

  // ******************** pattern's

  // 1. BASIC *******

  function basic2(arg) {
    setpattern("basic");

    const ctx = canvas.getContext("2d");

    var img = document.createElement("img");
    img.setAttribute("crossOrigin", "anonymous");

    rotatedImgURL ? (img.src = rotatedImgURL) : (img.src = imgUrl);

    // console.log(rotatedImgURL, "rotatedImgURL");
    // console.log(imgUrl, "imgUrl");

    // img.src = imgUrl

    var imgwidth = 0;
    var imgheight = 0;
    img.onload = function () {
       var imgW=img.width;
       var imgH=img.height;
        if(designSize1){
          imgwidth =designSize1
          imgheight=(imgH/imgW)*imgwidth;
        }

      //  console.log(imgW , imgH ,'image width and height in basic')
      // if (designSize1 == 5) {
      //   imgwidth = 188.976377953;
      //   imgheight=(imgH/imgW)*imgwidth;
      //   //imgheight = imgwidth * 1.5;
      //   console.log(imgwidth , imgheight ,'Anirudha image width and height in basic')
      // } else if (designSize1 == 10) {
      //   imgwidth = 377.952755906;
      //   imgheight=(imgH/imgW)*imgwidth;
      //   //imgheight = imgwidth * 1.5;
      // } else if (designSize1 == 15) {
      //   imgwidth = 566.929133859;
      //   imgheight=(imgH/imgW)*imgwidth;
      //  // imgheight = imgwidth * 1.5;
      // } else if (designSize1 == 20) {
      //   imgwidth = 755.905511812;
      //   imgheight=(imgH/imgW)*imgwidth;
      //   //imgheight = imgwidth * 1.5;
      // } else {
       // imgwidth = 94.48;
        //imgheight=(imgH/imgW)*imgwidth;
        //imgheight = imgwidth * 1.5;
     // }

      fillPattern(this, imgwidth, imgheight);
    };

    function fillPattern(img, w, h) {
      var origW = w;
      var origH = h;
      var orignalHeight = h;

      let i = 0;
      w = 0;
      h = 0;

      while (h < canvas.height + origH) {
        while (w < canvas.width) {
          ctx.drawImage(
            img,
            w,

            i % 2 == 0 ? h : h + origH * -1,

            origW,

            origH
          );

          w += origW;
          i++;
        }
        h += origH;
        i = 0;
        w = 0;
      }
    }
    // setrotation(false);
    // console.log("overlay basic", overLay);
    if (overLay == 1) {
      // console.log(" IF overlay sec");

      handleOverlay();
    }
  }

  //   2************** Half-Drop

  function halfDrop1() {
    setpattern("halfDrop");

    const ctx = canvas.getContext("2d");

    var img = document.createElement("img");
    img.setAttribute("crossOrigin", "anonymous");
    // img.src = imgUrl;
    rotatedImgURL ? (img.src = rotatedImgURL) : (img.src = imgUrl);

    var imgwidth = 0;
    var imgheight = 0;
    var imgwidth = 0;
    var imgheight = 0;
    img.onload = function () {
       var imgW=img.width;
       var imgH=img.height;
        if(designSize1){
          imgwidth =designSize1
          imgheight=(imgH/imgW)*imgwidth;
        }
          

      // if (designSize1 == 5) {
      //   imgwidth = 188.976377953;
      //   imgheight = imgwidth * 1.5;
      // } else if (designSize1 == 10) {
      //   imgwidth = 377.952755906;
      //   imgheight = imgwidth * 1.5;
      // } else if (designSize1 == 15) {
      //   imgwidth = 566.929133859;
      //   imgheight = imgwidth * 1.5;
      // } else if (designSize1 == 20) {
      //   imgwidth = 755.905511812;
      //   imgheight = imgwidth * 1.5;
      // } else {
      //   imgwidth = 94.48;
      //   imgheight = imgwidth * 1.5;
      // }

      fillPattern(this, imgwidth, imgheight);
    };

    function fillPattern(img, w, h) {
      var origW = w;
      var origH = h;

      let i = 0;
      w = 0;
      h = 0;

      while (h < canvas?.height + origH) {
        while (w < canvas?.width) {
          ctx.drawImage(
            img,

            w,
            i % 2 == 0 ? h : h + (origH / 2) * -1,

            origW,
            origH
          );

          w += origW;
          i++;
        }
        h += origH;
        i = 0;
        w = 0;
      }
    }
    if (overLay == 1) {
      handleOverlay();
    }
  }
  // 3 ******************   Half-Brick ***********

  function halfBrick1() {
    //   setmirror(false);
    //   setbasic(false);

    //   sethalfDrop(false);
    //   sethalfBrick(false);
    //   setcenter(false);
    setpattern("halfBrick");

    const ctx = canvas?.getContext("2d");

    var img = document.createElement("img");
    img.setAttribute("crossOrigin", "anonymous");
    // img.src = imgUrl;
    rotatedImgURL ? (img.src = rotatedImgURL) : (img.src = imgUrl);

    var imgwidth = 0;
    var imgheight = 0;

    var imgwidth = 0;
    var imgheight = 0;
    img.onload = function () {
       var imgW=img.width;
       var imgH=img.height;
        if(designSize1){
          imgwidth =designSize1
          imgheight=(imgH/imgW)*imgwidth;
        }
    // img.onload = function () {
    //   if (designSize1 == 5) {
    //     imgwidth = 188.976377953;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 10) {
    //     imgwidth = 377.952755906;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 15) {
    //     imgwidth = 566.929133859;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 20) {
    //     imgwidth = 755.905511812;
    //     imgheight = imgwidth * 1.5;
    //   } else {
    //     imgwidth = 94.48;
    //     imgheight = imgwidth * 1.5;
    //   }

      fillPattern(this, imgwidth, imgheight);
    };

    function fillPattern(img, w, h) {
      var origW = w;
      var origH = h;

      let i = 0;
      w = 0;
      h = 0;

      while (w < canvas?.width + origW) {
        while (h < canvas?.height) {
          ctx.drawImage(
            img,

            i % 2 == 0 ? w : w + (origW / 2) * -1,
            h,

            origW,
            origH
          );

          h += origH;
          i++;
        }
        w += origW;
        i = 0;
        h = 0;
      }
    }

    if (overLay == 1) {
      handleOverlay();
    }
  }

  // **********************  center ********
  function center1() {
    
    setpattern("center");

    var img = document.createElement("img");
    img.setAttribute("crossOrigin", "anonymous");

    rotatedImgURL ? (img.src = rotatedImgURL) : (img.src = imgUrl);

    var imgwidth = 0;
    var imgheight = 0;
    var imgwidth = 0;
    var imgheight = 0;
    img.onload = function () {
       var imgW=img.width;
       var imgH=img.height;
        if(designSize1){
          imgwidth =designSize1
          imgheight=(imgH/imgW)*imgwidth;
        }
    // img.onload = function () {
    //   if (designSize1 == 5) {
    //     imgwidth = 188.976377953;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 10) {
    //     imgwidth = 377.952755906;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 15) {
    //     imgwidth = 566.929133859;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 20) {
    //     imgwidth = 755.905511812;
    //     imgheight = imgwidth * 1.5;
    //   } else {
    //     imgwidth = 188.976377953;
    //     imgheight = imgwidth * 1.5;
    //   }

      fillPattern(this, imgwidth, imgheight);
    };

    function fillPattern(img, w, h) {
      const ctx = canvas?.getContext("2d");
     ctx.clearRect(0, 0, canvas?.width, canvas?.height);


      var origX = canvas?.width / 2;
      var origY = canvas?.height / 2;
      var origW = w;
      var origH = h;
      //  ********** main
      ctx.drawImage(
        img,
        canvas?.width / 2 - imgwidth / 2,
        canvas?.height / 2 - imgheight / 2,
        imgwidth,
        imgheight
      );
    }

    if (overLay == 1) {
      handleOverlay();
    }
  }

  // ***************** mirror
  function mirror1() {
    //   setmirror(false);
    //   setbasic(false);

    //   sethalfDrop(false);
    //   sethalfBrick(false);
    //   setcenter(false);
    setpattern("mirror");

    const ctx = canvas?.getContext("2d");

    var img = document.createElement("img");
    img.setAttribute("crossOrigin", "anonymous");
    // img.src = imgUrl;

    rotatedImgURL ? (img.src = rotatedImgURL) : (img.src = imgUrl);

    var imgwidth = 0;
    var imgheight = 0;
    
    var imgwidth = 0;
    var imgheight = 0;
    img.onload = function () {
       var imgW=img.width;
       var imgH=img.height;
        if(designSize1){
          imgwidth =designSize1
          imgheight=(imgH/imgW)*imgwidth;
        }


    // img.onload = function () {
    //   if (designSize1 == 5) {
    //     imgwidth = 188.976377953;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 10) {
    //     imgwidth = 377.952755906;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 15) {
    //     imgwidth = 566.929133859;
    //     imgheight = imgwidth * 1.5;
    //   } else if (designSize1 == 20) {
    //     imgwidth = 755.905511812;
    //     imgheight = imgwidth * 1.5;
    //   } else {
    //     imgwidth = 94.48;
    //     imgheight = imgwidth * 1.5;
    //   }

      fillPattern(this, imgwidth, imgheight);
    };

    function fillPattern(img, w, h) {
      var origW = w;
      var origH = h;

      let i = 0;
      w = 0;
      h = 0;
      let anir = canvas.width / (designSize1 * 2);

      console.log("anir", anir);

      const reflect = imgwidth * 2;
      while (h < canvas.height + origH) {
        while (w < canvas.width) {
          ctx.drawImage(
            img,

            w,
            i % 2 == 0 ? h : h + (origH / 1) * 1,

            origW,
            origH
          );

          ctx.scale(-1, 1);

          if (designSize1) {
            ctx.translate(-reflect * Math.ceil(cmWidth / (designSize1*2)), 0);

          // } else if (designSize1 == 10) {
          //   ctx.translate(-reflect * Math.ceil(cmWidth / 20), 0);
          // } else if (designSize1 == 15) {
          //   ctx.translate(-reflect * Math.ceil(cmWidth / 30), 0);
          // } else if (designSize1 == 20) {
          //   ctx.translate(-reflect * Math.ceil(cmWidth / 40), 0);
          // } else {
          //   console.log("anir65556");
           }

          ctx.drawImage(
            img,

            w,

            i % 2 == 0 ? h : h + (origH / 1) * 1,

            origW,
            origH
          );

          w += origW;
          i++;
        }

        h += origH;
        i = 0;
        w = 0;
      }
    }

    if (overLay == 1) {
      handleOverlay();
    }
  }
  //  ***************** rotation *******************//

  const [angle, setangle] = useState(90);
  // var angle = 0
  function handleRotate(arg) {
    // ( angle += arg)
    console.log(arg, "from handle Rotatt");
    const ctx = canvas?.getContext("2d");

    var img = new Image();
    img.src = imgUrl;
    img.onload = function () {
      var imgInstance = new fabric.Image(img, {
        scaleX: canvas?.width / img.width,
        scaleY: canvas?.height / img.height,
        id: "rotatedImg",
      });

      canvas?.add(imgInstance);
      imgInstance.rotate(angle);
      canvas?.renderAll();
      // setbasic(true);
      var base64Image = canvas?.toDataURL("image/jpeg");

      setrotatedImgURL(base64Image);

      if (base64Image) {
        var objects = canvas?._objects;
        for (var i = 0; i < objects.length; i++) {
          canvas?.remove(objects[i]);
        }
      }
    };
    if (arg === "basic") {
      console.log("basic 2 call from handleRotate onclick");
      basic2();
    } else if (arg == "halfDrop") {
      console.log("basic 2 call from handleRotate onclick");
      halfDrop1();
    }
  }

  
  useEffect(() => {
    basic2()
   
  },[inputValue])
  

  useEffect(() => {
    console.log("rotatedImgURL", rotatedImgURL, "pattern", pattern);
    if (pattern === "basic" ) {
      basic2();
    } else if (pattern == "halfDrop") {
      halfDrop1();
    } else if (pattern == "halfBrick") {
      halfBrick1();
    } else if (pattern == "center") {
      center1();
    } else if (pattern == "mirror") {
      mirror1();
    } else {
      basic2();
    }
  }, [rotatedImgURL]);

  useEffect(() => {
    if (pattern === "basic" ) {
      basic2();
    } else if (pattern == "halfDrop") {
      halfDrop1();
    } else if (pattern == "halfBrick") {
      halfBrick1();
    } else if (pattern == "center") {
      center1();
    } else if (pattern == "mirror") {
      mirror1();
    } else {
      basic2();
    }
  }, [designSize1]);



  useEffect(()=>{
      
    
   

   if(term==false){
    tsetdisplay('block')
  }else{
    tsetdisplay('none')
  }


  },[term])

 


  const handleCart=()=>{

    if(size==null){
      ssetdisplay('block');
   }else{
     ssetdisplay('none');
   }

   if(term==false ||term==null){
    tsetdisplay('block')
  }else{
    tsetdisplay('none')
  }

    if(size!=null && term==true){
      console.log('Run add to cart');
    }

  }

  return (
    <>
      <Col xs={12} md={4} className="mainRightSection" style={{paddingLeft: 17}}>
        <div>
          {/* <img src={imgUrl} alt="" /> */}
          <h4>{productData?.product_name}</h4>
          {/* <h2>Add Image:</h2>
                    <input type="file" className="btnFinalUp" onChange={handleChange} /> */}

          <div className="selectuploade_sec">
            <div className="col-sm-6" style={{ padding: 0 }}>
              <label for="file-upload" class="custom-file-upload">
                <img src={upload} alt="" />
                <span> Upload Design</span>
              </label>
              <input
                id="file-upload"
                type="file"
                className="upload_sec"
                onChange={handleChange}
              />
            </div>
            <div
              className="select_btn col-sm-6"
              style={{ padding: "0px 0 0 10px" }}
            >
              <Button href="#">
                <img src={touchscreen} alt="" />
                <span> Select Design</span>{" "}
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>
               {productData?.short_description}
            </span>
            <img src={imgUrl} className="prieveiwImg_sec" />
          </div>
          <div className="designSize_Main Sec">
            <h4 className="designSize_textValue">Design Size</h4>
            <div className="sizeDesign_option">
             {productData?.cfd__pf_deisgn_size.map((d,i)=>{ 
               return <input
                  type="button"
                 
                 value={d.key+' '+productData?.cfd_unit}
                 
                 onClick={()=>handleDesignSize(d.value)}
                />

              })}

                {/* <input
                 type="button"
                 value="5 cm"
                 onClick={() => handleDesignSize(10)}
                />
              <input
                type="button"
                value="10 cm"
                onClick={() => handleDesignSize(10)}
              />
              <input
                type="button"
                value="15 cm"
                onClick={() => handleDesignSize(15)}
              />
              <input
                type="button"
                value="20 cm"
                onClick={() => handleDesignSize(20)}
              /> */}
              {/* previous code @ */}
              {/* <input type="text" value='20 cm' onClick={() => handleDesignSize(20)} disabled /> */}
            </div>
          </div>

          <div className="Pattern_MainSec">
            <h4 className="designSize_text">Arrangment</h4>

            <Tab.Container id="left-tabs-example" defaultActiveKey="">
              <Row>
                <Col sm={12}>
                  <Nav
                    variant="pills"
                    className="flex-row"
                    style={{ columnGap: 10 }}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        className="rotate_newText"
                        onClick={() => {
                          handleRotate(pattern);
                          setangle(angle + 90);
                        }}
                        style={{
                          backgroundColor: isActiveNew ? "#d1c3c329" : "",
                        }}
                      >
                        <img
                          style={{
                            width: 23,
                            marginRight: 5,
                          }}
                          src={rotate}
                        />
                        Rotate Pattern
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        // style={{
                        //     backgroundColor: isActive ? '#d1c3c329' : '',

                        //     borderRadius: isActive ? '10px 10px 0px 0px' : '',
                        //     padding: isActive ? 11 : '',
                        //     margin: isActive ? 0 : '',
                        //     border: isActive ? 0 : '',
                        //     boxShadow: isActive ? '0 0px 0px 0 rgb(34 34 34 / 15%)' : '',
                        // }}
                        // onClick={handleClick}
                        onClick={() => {
                          handleClick();
                          handleClick1();
                        }}
                      >
                        Pattern Type
                        <img
                          style={{
                            width: 15,
                            marginLeft: 5,
                            height: 15,
                          }}
                          src={arrow}
                        />
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first"></Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {/* 👇️ show elements on click */}
                      {isShown && (
                        productData?.CFD_pattern_type_enable==1?
                        <ul className="PatternType_outer">
                          
                         {productData?.CFD_pattern_basic_enable==1? 
                          <li class="" onClick={() => basic2()}>
                            <a href="#" rel="fg_img_repeat" layout="tile">
                              <img
                                src="https://www.texindiamart.com/fabric-generator/layout_repeat.png"
                                alt=""
                                style={{ border: "1px solid black" }}
                              />
                            </a>
                            <h6>Basic</h6>
                          </li>:<></>}
                           {productData?.CFD_pattern_halfdrop_enable==1?
                          <li class="" onClick={halfDrop1}>
                            <a href="#" rel="fg_img_repeat" layout="tile">
                              <img
                                src="https://www.texindiamart.com/fabric-generator/layout_vert.png"
                                   
                                alt=""
                              />
                            </a>
                            <h6>Half-drop</h6>
                          </li>:<></>}
                          {productData?.CFD_pattern_halfbrick_enable==1?
                          <li class="" onClick={halfBrick1}>
                            <a href="#" rel="fg_img_repeat" layout="tile">
                              <img
                                src="https://www.texindiamart.com/fabric-generator/layout_brick.png"

                                alt=""
                              />
                            </a>
                            <h6>Half-brick</h6>
                          </li>
                           :<></>}
                           {productData?.CFD_pattern_center_enable==1?
                          <li class="" onClick={center1}>
                            <a href="#" rel="fg_img_repeat" layout="tile">
                              <img
                                src="https://www.texindiamart.com/fabric-generator/layout_center.png"

                                alt=""
                              />
                            </a>
                            <h6>Center</h6>
                          </li>:<></>}
                          {productData?.CFD_pattern_mirror_enable==1?
                          <li class="" onClick={mirror1}>
                            <a href="#" rel="fg_img_repeat" layout="tile">
                              <img
                                src="https://www.texindiamart.com/fabric-generator/layout_repeat.png"

                                alt=""
                              />
                            </a>
                            <h6>Mirror</h6>
                          </li>
                          :<></>}
                        </ul>
                      :<></>)}

                      {/* <ul className='PatternType_outer'>
                                                <li class="">
                                                    <a href="#" rel="fg_img_repeat" layout="tile">
                                                        <img src="https://www.texindiamart.com/fabric-generator/layout_center.png" alt="" />
                                                    </a>
                                                    <h6>Basic</h6>
                                                </li>

                                                <li class="">
                                                    <a href="#" rel="fg_img_repeat" layout="tile">
                                                        <img src="https://www.texindiamart.com/fabric-generator/layout_repeat.png" alt="" />
                                                    </a>
                                                    <h6>Half-drop</h6>
                                                </li>
                                                <li class="">
                                                    <a href="#" rel="fg_img_repeat" layout="tile">
                                                        <img src="https://www.texindiamart.com/fabric-generator/layout_vert.png" alt="" />
                                                    </a>
                                                    <h6>Half-brick</h6>
                                                </li>
                                                <li class="">
                                                    <a href="#" rel="fg_img_repeat" layout="tile">
                                                        <img src="https://www.texindiamart.com/fabric-generator/layout_brick.png" alt="" />
                                                    </a>
                                                    <h6>Center</h6>
                                                </li>
                                                <li class="">
                                                    <a href="#" rel="fg_img_repeat" layout="tile">
                                                        <img src="https://www.texindiamart.com/fabric-generator/layout_repeat.png" alt="" />
                                                    </a>
                                                    <h6>Mirror</h6>
                                                </li>
                                            </ul> */}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            {/* <div className="mainSize">
                        <div className='col-sm-6'>
                            <button className='show_btnNext' onClick={handleClick}>Click</button>

                        </div>
                        <div className='col-sm-6'>
                            <button className='show_btnNext' onClick={handleClick}>Click</button>

                           
                            {isShown && (
                                <div>
                                    <h2>Some content here</h2>
                                </div>
                            )}

                       
                            {isShown && <CanvasTest />
                            }
                        </div>

                        </div> */}

            {/* <Tabs
                            defaultActiveKey="profile"
                            id="uncontrolled-tab-example"
                            className=""
                        >
                            <Tab eventKey="home" title="Rotate The Pattern " >

                            </Tab>
                            <Tab eventKey="profile" title=" Pattern Type">
                                <ul className='PatternType_outer'>
                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_center.png" alt="" />
                                        </a>
                                        <h6>Basic</h6>
                                    </li>

                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_repeat.png" alt="" />
                                        </a>
                                        <h6>Half-drop</h6>
                                    </li>
                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_vert.png" alt="" />
                                        </a>
                                        <h6>Half-brick</h6>
                                    </li>
                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_brick.png" alt="" />
                                        </a>
                                        <h6>Center</h6>
                                    </li>
                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_repeat.png" alt="" />
                                        </a>
                                        <h6>Mirror</h6>
                                    </li>
                                </ul>
                            </Tab>
                        </Tabs> */}
          </div>
          {/* <div className='Pattern_MainSec'>
                        <h4 className='designSize_text'>Rotate The Pattern</h4>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-light" className='selectpattern_sec'>
                                Pattern Type
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#">
                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_repeat.png" alt="" />
                                        </a>
                                    </li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_repeat.png" alt="" />
                                        </a>
                                    </li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_mirror.png" alt="" />
                                        </a>
                                    </li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li class="">
                                        <a href="#" rel="fg_img_repeat" layout="tile">
                                            <img src="https://www.texindiamart.com/fabric-generator/layout_vert.png" alt="" />
                                        </a>
                                    </li>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div> */}

          <div className="designSize_MainSec">
            <h4 className="designSize_text">Choose a fabric</h4>
            {/* <Form.Select aria-label="Default select example">
                            <option>Select a Fabric</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>

                        </Form.Select> */}
            <Button
              className="PopUp_rightBtn"
              variant="primary"
              onClick={() => setShow(true)}
            >
              {fabricName}
            </Button>
          </div>

          <div className="FabricSize_MainSec">
            <h4 className="designSize_text">Select Size and Amount</h4>
            <div className="selectSize_section">
              {/* <input type="text" value='2' disabled /> */}
              <Form.Select aria-label="Default select example" onChange={(e) =>setSize(e.target.value)} >
                <option value={null}>Select a Fabric</option>
                    
                {fabricSize.map((c,i)=>{
                    return <option value={c?.price}>{c?.title}</option>  
                })}
              </Form.Select>
              
            </div>
            <p style={{color:'red' ,display:sdisplay ,margingTop:10}}>This field is mandetory</p>
          </div>
          <div className="prce_mainSec">
            <div className="col-sm-6 qty_sec">
              <h6>Qty</h6>
              {/* <input type="number" value='1' /> */}
              <input type="number" value={qty} class="form-control" onChange={(e)=>setqty(e.target.value)} />
              
            </div>
            <div className="col-sm-6 price_sec">
              <span>$ {amount*qty}</span>
            </div>
          </div>

          <div className="checFinalBtn_sec">
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check 
                type="checkbox"
                onClick={(e)=>{setTerm(e.target.checked) }}
                label="I have understood the Terms & Conditions"
              />
            </Form.Group>
            <p style={{color:'red' ,display:tdisplay}}>Accept our terms & condition </p>
          </div>

          <div className="AddCart_btnSec">
            <button variant="outline-primary" onClick={()=>handleCart()}>
              <span>Add To Cart</span>
              <img src={cart} alt="" />
            </button>{" "}
          </div>

          {/* <Button variant="primary" onClick={() => setShow(true)}>
        Custom Width Modal
      </Button> */}

          <Modal  className="selectFabrIc_right"
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Custom Modal Styling
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {productData?.cfd__pf_febric.map((fabric,i)=>{
                   
                  return <FabricOptions fabric={fabric} setfabricData={setfabricData} setfabricSize={setfabricSize} setfabricName={setfabricName} setamount={setamount} setShow={setShow} url={url}/>
            })}     
            </Modal.Body>
          </Modal>
        </div>
      </Col>
    </>
  );
}
