import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useLayoutEffect,
} from "react";
import { Col } from "react-bootstrap";
import flo from "../assets/images/flo.jpg";
import pillow from "../assets/images/pillow.png";
import PL from "../assets/images/PL.png";
import PL1 from "../assets/images/PL1.png";
import PL2 from "../assets/images/PL2.png";
import PL3 from "../assets/images/PL3.png";
import PL4 from "../assets/images/PL4.png";
import PL5 from "../assets/images/PL5.png";
import { fabric } from "fabric";

const NewCanvas = (props) => {
  const {

    setbasic,
    sethalfDrop,
    sethalfBrick,
    setoverLayImg,
    overlayImg,
    designSize1,
    setdesignSize,
    rotation,
    inputValue,
    setOverLay,
    
    overLay,
  canvas,
  canvas2,
  setbasic1,
  setCh,
  ch,
  cw,
  setCw,
  cmHeight,
  cmWidth,
  dynamicHeight ,
  dynamicWidth ,
  productData,
  url
  } = props;
 
  // const [overlayImg, setoverLayImg] = useState(null);
  const [showPng , setpng]=useState();
  const [rotatedImgURL, setrotatedImgURL] = useState()
 
  // console.log("designSize1 from canvas",designSize1);

   
    
  /***************************** Handle Overlay *************************/

  const handleOverlay = () => {
    const ctx = canvas.getContext("2d");
    // var dummy = new Image();
    // dummy.src = "dummy";

    // ctx.drawImage(dummy, 0, 0, canvas?.width, canvas?.height);
    var overlay = new Image();
    overlay.src = overlayImg;

    overlay.onload = function () {
      ctx.drawImage(overlay, 0, 0, canvas?.width, canvas?.height);
    };

    setOverLay(1);
  };

 

  //  set Ruler's Height & width Dynamically in cm
 

  // var dynamicWidth = 37.7952755906 * cmWidth;

  // var dynamicHeight = 37.7952755906 * cmHeight;

  var size = designSize1 * 37.7952755906;

  // var imgUrl = inputValue;

 

  const [canvas2CW, setcanvas2CW] = useState(null);
  const [canvas2CH, setcanvas2CH] = useState(null);


  // useEffect(() => {
  //   console.log("overly useEffect in newCanvas   ");

  //   if (overLay == 1) {
  //     if (overlayImg != null) {
  //       const ctx = canvas?.getContext("2d");
  //       ctx.clearRect(0, 0, canvas?.width, canvas?.height);

  //       // if (setbasic) {
  //       //   // basic1();
  //       // } else if (sethalfBrick) {
  //       //   // halfBrick1();
  //       // } else if (sethalfDrop) {
  //       //   // halfDrop1();
  //       // }
  //     }
  //   }
  // }, [overlayImg]);

  useLayoutEffect(() => {
   
    if (canvas) {
      setCw(canvas.width);
      setCh(canvas.height);
    }
   

  }, []);

  useLayoutEffect(() => {
   
    if (canvas2) {
      setcanvas2CW(canvas2.width);
      setcanvas2CH(canvas2.height);
    }

   

  }, [inputValue]);

   


//  useEffect(() => {
  const canvasWithRuler = () => {
    
    var ctx2 = canvas2?.getContext("2d")


  drawRulers(ctx2, 37.7952755906, cmWidth, cmHeight);

  function drawRulers(t, e, n, s) {
    const i = 37.7952755906;
    // const i = 27;

    //  Ruler's  x-axis & Y-axis   grey
    t.fillStyle = "white";
    t.fillRect(i, 0, dynamicWidth, i);
    t.fillRect(0, i, i, dynamicHeight);
    t.fillStyle = "black";
    t.fillRect(0, 0, i, i);
    t.beginPath();
    t.font = "16px Lato";
    t.fillStyle = "white";
    t.textAlign = "center";
    t.fillText("cm.", i / 2, i - 15);

    let l = true;
    let c = true;

    t.fillStyle = "black";
    for (let d = 1; n >= d; d += 1) {
      const h = d * e;
      let u = `${d}`;
      if (
        (12 > n && !l) ||
        (32 > n && !l && c) ||
        (n >= 32 && d % 12 === 0)
      ) {
        t.fillText(u, h + i, i - 8);
      } else {
        t.fillText(l ? d : d, h + i, i - 8);
      }
      l = !l;
      if (!l) {
        c = !c;
      }
    }

    l = true;
    c = true;

    t.textAlign = "right";
    for (let p = 1; s >= p; p += 1) {
      const f = p * e;
      const u = `${p}`;
      if (
        (12 > s && !l) ||
        (32 > s && !l && c) ||
        (s >= 32 && p % 12 === 0)
      ) {
        t.fillText(u, i - 8, f + i);
      } else {
        t.fillText(p ? p : "\u2014", i - 8, f + i);
      }
      l = !l;
      if (!l) {
        c = !c;
      }
    }
  }
};
canvasWithRuler()


//  }, [])
 

useEffect(() => {
  canvasWithRuler()
 
}, [canvas,inputValue,designSize1,overlayImg,canvas2]);



  const removeOverlay=()=>{
      
     setoverLayImg('dummy')
    

  }

//   canvas?.on('mouse:move', console.log('working here for changes'));

  return (
    <>
      <Col xs={12} md={8} className="MainLeftSection">
        <div className="innerLeft_sec">
          <div className="Outer_imageSec">
            <img src={productData?.CFD_img_high_resolution} onClick={()=>removeOverlay()}  />
          </div>
          {productData?.cfd__pf_mock_up?.map((img,i)=>{
            return <div className="Outer_imageSec">
              <img
                src={img}
                onClick={() => {
                  handleOverlay();
                  setoverLayImg(
                    {img}
                  );
                }}
              />
            </div>
           })}  
          {/* <div className="Outer_imageSec">
            <img
              src="https://production-cdn1.patternbank.com/uploads/masks/size_600/dress-mask.png?1473864191"
              onClick={() => {
                handleOverlay();
                setoverLayImg(
                  "https://production-cdn1.patternbank.com/uploads/masks/size_600/dress-mask.png?1473864191"
                );
              }}
            />
          </div>
          <div className="Outer_imageSec">
            <img
              src="https://production-cdn.patternbank.com/uploads/masks/size_600/leggings-mask.png?1473864195"
              onClick={() => {
                handleOverlay();
                setoverLayImg(
                  "https://production-cdn.patternbank.com/uploads/masks/size_600/leggings-mask.png?1473864195"
                );
              }}
            />
          </div>
          <div className="Outer_imageSec">
            <img
              src="https://production-cdn2.patternbank.com/uploads/masks/size_600/kaftan-mask.png?1473864198"
              onClick={() => {
                handleOverlay();
                setoverLayImg(
                  "https://production-cdn2.patternbank.com/uploads/masks/size_600/kaftan-mask.png?1473864198"
                );
              }}
            />
          </div> */}
        </div>
        <div className="cannvas_outer ">
          <div
            id="canvas_div"
            style={{
              float: "left",
              position: "relative",
            }}
          >
            <canvas
              id="canvasBottom"
              style={{
                position: "relative",
                border: "1px solid black",
                marginTop: 50,
                marginLeft: 25,
                border:"2px solid green",
                left: 13+'px',
                top: -12+'px'
              }}
              // width={dynamicWidth}
              // height={dynamicHeight}
            ></canvas>

            <canvas
              id="canvas"
              style={{
                position: "absolute",
                //marginTop: -808.5,
                marginLeft: -38,
                // border:"2px solid red",
                 top: 0,
                 left: 38+'px',
                 right: 0
              }}
              // height={dynamicHeight + 37.7952755906}
              // width={dynamicWidth + 37.7952755906}
            ></canvas>

            {/* <img src={imgUrl} style={{ visibility: "hidden" }} id="geeks" /> */}
          </div>
        </div>
      </Col>
    </>
  );
};

export default NewCanvas;
