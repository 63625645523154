import React ,{useEffect , useState} from "react";
import axios from "axios";
const FabricOptions = (props) => {
    
   const [offMode , setoffMode]=useState(null)

   const getFabricData=(id)=>{
     
    axios.post(props.url,{source:"febric",febric_id:id}).then(res=>{props.setfabricData(res?.data?.febricData?.cfd_febric_size);props.setfabricName(res.data?.febricData?.title);props.setfabricSize(res.data?.febricData?.cfd_febric_size) ;props.setamount(res?.data?.febricData?.price) ;setoffMode(res.data?.febricData);})
   }

   useEffect(()=>{
        if(offMode?.id){
         props.setShow(false);
        }
   },[offMode])
  

  return (
    <>
      <div
        className="col-sm-12 row"
        style={{
          borderBottom: "1px solid #22222226",
          padding: 15,
          justifyContent: "space-between",
        }}
      >
         
        <div className="col-sm-5">
          <img src={props.fabric?.fimage} style={{ width: 200, height: 200 }} />
        </div>
        <div className="col-sm-7 row">
          <h3
            style={{
              padding: 0,
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {props.fabric?.title}
          </h3>
          <p style={{ padding: 0, fontSize: 14 }}>{props.fabric?.shortdesc}</p>
          <span
            className="price_sec"
            style={{ color: "#cc1e6b", fontSize: 16, fontWeight: 700 }}
          >
            ${props.fabric?.price}
          </span>
          <div className='button-bottom'>
                <button
                className="btn" style={{
                    width: 85, height: 35, backgroundColor: "#cc1e6b", fontSize: 14,
                    color: 'white', padding: "0.275rem 0.55rem"
                    }}

                    onClick={()=>{getFabricData(props.fabric?.id)}}
                >
                    Selected
                </button>

                <a href={props.fabric?.url} className='learn_more'>Learn More</a>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default FabricOptions;
