import React, { useState, useEffect, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import "./../assets/css/style.css";
import SideBar from "../modules/SideBar";
import NewCanvas from "../modules/NewCanvas";
import Canvas from "../modules/Canvas";
import { fabric } from "fabric";

const MainSection = () => {

    const[url, setUrl]=useState('https://wtp10.developmentstagingserver.com/wp-json/custom-fabric-designer/v1/designing')

  // *************
  const[productData , setproductData]=useState(null);
  const [fabricData , setfabricData] = useState(null);
  const[fabricSize , setfabricSize] = useState([]);
  const[fabricName, setfabricName]=useState(null);
  const [amount , setamount] = useState(null);
  const [inputValue, setinputValue] = useState();
  const [basic1, setbasic1] = useState();
  const [ruler, setruler] = useState(true)
  const [overLay, setOverLay] = useState(0);
     const [overlayImg, setoverLayImg] = useState(null);
     const [designSize1, setdesignSize] = useState(5)
   


  // *********** variables

  const [cw, setCw] = useState(null);
  const [ch, setCh] = useState(null);
   

  const cmWidth = 17;
  const cmHeight = 17;

  
 const dynamicWidth = 37.7952755906 * cmWidth;
  
 const dynamicHeight = 37.7952755906 * cmHeight;

  useEffect(() => {
    axios
      .post(
         url,
        { source: "product", product_id: 144 }
      )
      .then((res) => {
        setproductData(res?.data?.productData) ; setfabricName(fabricData?.title);setamount(fabricData?.price); setfabricData(res?.data?.productData?.cfd__pf_febric);setfabricName(res?.data?.productData?.cfd__pf_febric[0]?.title);setamount(res?.data?.productData?.cfd__pf_febric[0]?.price);
        setamount(res?.data?.productData?.cfd__pf_febric[0]?.price); 
         setdesignSize(res?.data?.productData?.cfd__pf_deisgn_size[0]?.value)
        console.log(res?.data?.productData?.cfd__pf_deisgn_size[0]?.value , 'design size from api');
         
        setinputValue(res?.data?.productData?.CFD_img_high_resolution)
        setfabricData(()=>{
          axios.post( url,{source:'febric' , febric_id:res?.data?.productData?.cfd__pf_febric[0]?.id}).then(
            resp=>{setfabricSize(resp?.data?.febricData?.cfd_febric_size)}
          )
        })
         
      });
    
  }, []);
  
  
//   useEffect(()=>{
//     if(productData){
//         axios.post('https://wtp10.developmentstagingserver.com/wp-json/custom-fabric-designer/v1/designing',{
//          source:'febric', febric_id:productData?.cfd__pf_febric[0]?.id
//         }).then(res=>{setfabricSize(res?.data?.febricData?.cfd_febric_size) ; console.log(res?.data?.febricData?.cfd_febric_size)})
//     }
   
//  },[])



 

  const canvas = useRef(null);  
  const canvas2 = useRef(null);

  canvas.current = new fabric.StaticCanvas("canvasBottom", {width:dynamicWidth,height:dynamicHeight,
    preserveObjectStacking: true,
  });
  canvas2.current = new fabric.StaticCanvas("canvas",{width:(dynamicWidth+37.7952755906),height:(dynamicHeight+37.7952755906) ,preserveObjectStacking: true,});
  
  return (
    <div>
      <Container className="main_Section">
        <Row style={{ display: "flex" }}>
          <Canvas
            inputValue={inputValue}
            canvas={canvas.current}
            canvas2={canvas2.current}
            setbasic1={setbasic1}
            setCh={setCh}
            ch={ch}
            cw={cw}
            setCw={setCw}
            cmHeight={cmHeight}
            cmWidth={cmWidth}
            setinputValue={setinputValue}
            setOverLay={setOverLay}
            overLay={overLay}
          setoverLayImg={setoverLayImg}
          overlayImg={overlayImg}
          dynamicHeight={dynamicHeight}
          dynamicWidth={dynamicWidth}
          setdesignSize={setdesignSize}
          designSize1={designSize1}
          /******State for api******/
          productData={productData}
          fabricData={fabricData}  
          setfabricData={setfabricData}
          fabricSize={fabricSize} 
          setfabricSize={setfabricSize}
          fabricName={fabricName}
          setfabricName={setfabricName}
          amount={amount}  
          setamount={setamount}
          url={url}
          

          />

          <SideBar
            setoverLayImg={setoverLayImg}
            overlayImg={overlayImg}
            setOverLay={setOverLay}
            overLay={overLay}
            setinputValue={setinputValue}
            canvas={canvas.current}
            canvas2={canvas2.current}
            basic1={basic1}
            setCh={setCh}
            ch={ch}
            cw={cw}
            setCw={setCw}
            cmHeight={cmHeight}
            cmWidth={cmWidth}
            inputValue={inputValue}
            dynamicHeight={dynamicHeight}
            dynamicWidth={dynamicWidth}
            setdesignSize={setdesignSize}
            designSize1={designSize1}
            /****** State for api *****/
            productData={productData}
            fabricData={fabricData}  
            setfabricData={setfabricData}
            fabricSize={fabricSize} 
            setfabricSize={setfabricSize}
            fabricName={fabricName}
            setfabricName={setfabricName}
            amount={amount}  
            setamount={setamount}
            url={url}
          />
          {/* <NewCanvas/> */}
        </Row>
      </Container>
    </div>
  );
};

export default MainSection;
